import React from "react"
import styled from "styled-components"
import { down, up } from "styled-breakpoints"
import { Quote } from "../../utils/imgImport"
import { useTranslation } from "gatsby-plugin-react-i18next"

const Tab = styled.div`
  background: #f2f2f2;
  padding: 50px 0 80px;
  ${down("md")} {
    padding: 20px 0 40px;
  }
  h2 {
    font-family: "CeraGR-Bold";
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.02em;
    color: #002f6c;
  }
  .content {
    font-family: "CeraGR-Regular";
    font-size: 18px;
    line-height: 28px;
    color: #002f6c;
  }
`
const Row = styled.div`
  flex-direction: column-reverse;
  ${up("md")} {
    flex-direction: row;
  }
`
const Frame = styled.div`
  background: rgba(0, 47, 108, 0.1);
  border-radius: 8px;
  padding: 50px;
  text-align: center;
  width: 100%;
  max-width: 100%;
  margin: 24px 0;
  ${up("md")} {
    margin: 0;
    max-width: 390px;
  }
  p {
    font-family: "CeraGR-Medium";
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: rgba(0, 47, 108, 0.9);
  }
  h4 {
    font-family: "CeraGR-Bold";
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    color: rgba(0, 47, 108, 0.9);
    position: relative;
    margin: 0;
    display: inline;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 8px;
      background-color: #af8373;
      left: 0;
      bottom: -12px;
    }
  }
`
const QuoteText = styled.p`
  position: relative;
  font-family: "CeraGR-BoldItalic";
  font-size: 32px;
  line-height: 42px;
  letter-spacing: -0.02em;
  color: #af8373;
  padding-left: 80px;
  &::before {
    content: "";
    position: absolute;
    background-image: url(${Quote});
    width: 67px;
    height: 55px;
    left: 0;
  }
  margin-top: 30px;
  ${up("md")} {
    margin-top: 0;
  }
`

const AboutTab = () => {
  const { t } = useTranslation()

  return (
    <Tab id="gsevee">
      <div className="container">
        <h2>{t("about:whoWeAre.title")}</h2>
        <div className="row my-5 align-items-center">
          <div className="col-md-6">
            <p className="content">
              {t("about:whoWeAre.sectionOne.description")}
            </p>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <Frame>
              <p className="mb-2">
                {t("about:whoWeAre.sectionOne.cardSubtitle")}
              </p>
              <h4>{t("about:whoWeAre.sectionOne.cardTitle")}</h4>
            </Frame>
          </div>
        </div>
        <Row className="row my-5 align-items-center">
          <div className="col-md-6 d-flex justify-content-start">
            <Frame>
              <h4>{t("about:whoWeAre.sectionTwo.cardTitle")}</h4>
              <p className="mt-4">
                {t("about:whoWeAre.sectionTwo.cardSubtitle")}
              </p>
            </Frame>
          </div>
          <div className="col-md-6">
            <p className="content">
              {t("about:whoWeAre.sectionTwo.description")}
            </p>
          </div>
        </Row>
        <div className="row my-5 align-items-center">
          <div className="col-md-6">
            <p className="content">
              {t("about:whoWeAre.sectionThree.description")}
            </p>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <QuoteText>{t("about:whoWeAre.sectionThree.quote")}</QuoteText>
          </div>
        </div>
      </div>
    </Tab>
  )
}

export default AboutTab
