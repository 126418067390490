import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { down, up } from "styled-breakpoints"
import styled from "styled-components"
import { useTranslation } from "gatsby-plugin-react-i18next"

const Tab = styled.div`
  background-color: #f2f2f2;
  padding-top: 100px;
  padding-bottom: 100px;
  .role-img {
    max-width: 388px;
    mix-blend-mode: luminosity;
    border-radius: 8px;
    margin-top: 30px;
    ${up("md")} {
      margin-top: 0;
    }
  }
  .role {
    font-family: "CeraGR-Bold";
    font-size: 48px;
    line-height: 56px;

    &-index {
      border-radius: 50%;
      border: 1px solid #af8373;
      width: 64px;
      height: 64px;
      flex-shrink: 0;
      margin-right: 30px;
    }
  }
  p {
    font-family: "CeraGR-Regular";
    font-size: 18px;
    line-height: 28px;
  }
  ${down("md")} {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

export default function InstitutionalTab() {
  const { t } = useTranslation()
  const roles = t("about:institutional.roles")

  return (
    <Tab id="role">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex flex-column">
            <h2 className="role">{t("about:institutional.title")}</h2>
            <p className="role-content mt-auto">
              {t("about:institutional.description")}
            </p>
          </div>
          <div className="col-md-6 text-center">
            <StaticImage
              className="role-img"
              src="../../images/role.jpg"
              alt="role"
            />
          </div>
        </div>
        <div className="row mt-5 py-5">
          {roles.map((role, index) => (
            <Role key={index} number={index + 1} description={role} />
          ))}
        </div>
      </div>
    </Tab>
  )
}

function Role({ description, number }) {
  return (
    <div className="col-md-6 d-flex mt-5">
      <p
        css={`
          display: flex;
          align-items: center;
          justify-conter: center;
        `}
        className="role-index"
      >
        <span
          css={`
            font-family: "CeraGR-Bold";
            font-size: 48px;
            line-height: 50px;
            text-align: center;
            width: 100%;
            padding-bottom: 6px;
          `}
        >
          {number}
        </span>
      </p>
      <p>{description}</p>
    </div>
  )
}
