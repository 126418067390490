import React from "react"
import { up } from "styled-breakpoints"
import styled from "styled-components"
import AffiliatesOneEl from "../../images/affiliate1-el.png"
import AffiliatesTwoEl from "../../images/affiliate2-el.png"
import AffiliatesOneEn from "../../images/affiliate1-en.png"
import AffiliatesTwoEn from "../../images/affiliate2-en.png"
import { Typography } from "../styled/typography"
import { useTranslation } from "gatsby-plugin-react-i18next"

const AffiliatedImage = styled.img`
  height: 140px;
  width: 100%;
  object-fit: contain;

  ${up("md")} {
    object-fit: cover;
  }
`

const Tab = styled.div`
  padding: 50px 0;
  .title {
    font-family: "CeraGR-Bold";
    font-size: 48px;
    line-height: 56px;
    color: #002f6c;
  }
  ${up("md")} {
    padding: 100px 0;
  }
`
const AffiliateBox = styled.div`
  padding: 30px 24px;
  background: #ffffff;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  height: 100%;

  ${up("md")} {
    height: 280px;
  }

  &:hover {
    outline: 2px solid #002f6c;
  }
`

export default function AffiliatesTab() {
  const { t, i18n } = useTranslation()

  const affiliates = [
    {
      img:
        i18n.resolvedLanguage === "el-gr" ? AffiliatesOneEl : AffiliatesOneEn,
      to: "https://imegsevee.gr",
      text: t("about:bodies.affiliateOneText"),
      color: "#852A41",
    },
    {
      img:
        i18n.resolvedLanguage === "el-gr" ? AffiliatesTwoEl : AffiliatesTwoEn,
      text: t("about:bodies.affiliateTwoText"),
      color: "#025D5E",
      to: "https://www.kekgsevee.gr ",
    },
  ]

  return (
    <Tab id="bodies">
      <div className="container">
        <h2 className="title">{t("about:bodies.title")}</h2>
        <div className="row">
          {affiliates.map((item, idx) => (
            <div className="col-md-6 mt-5" key={idx}>
              <a href={item.to} target="_blank" rel="noreferrer">
                <AffiliateBox>
                  <AffiliatedImage src={item.img} alt="affiliates" />
                  <Typography
                    className="mt-4"
                    size={16}
                    height={18}
                    color={item.color}
                  >
                    {item.text}
                  </Typography>
                </AffiliateBox>
              </a>
            </div>
          ))}
        </div>
      </div>
    </Tab>
  )
}
