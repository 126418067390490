import React, { useState } from "react"
import { down, up } from "styled-breakpoints"
import styled from "styled-components"
// import Select from "react-select"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import AboutTab from "../components/about/about-tab"
import AdminTab from "../components/about/admin-tab"
import AffiliatesTab from "../components/about/affiliates-tab"
import CategoryTab from "../components/about/category-tab"
import ChallengeTab from "../components/about/challenge-tab"
import InstitutionalTab from "../components/about/institutional-tab"
import PowerTab from "../components/about/power-tab"
import TimelineTab from "../components/about/timeline-tab"
import CategoryButton from "../components/common/CategoryButton"
import Layout from "../components/layout"
import Seo from "../components/seo"
import StickyWrapper from "../components/sticky/sticky-wrapper"
import Navbar from "../components/ui/navbar"
import { HeroBack } from "../utils/imgImport"

const AboutHero = styled.section`
  position: relative;
  background: linear-gradient(0, #63c6dc -50%, #002f6c 105%);
  overflow-x: hidden;
  &::before {
    content: "";
    position: absolute;
    background-image: url(${HeroBack});
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    background-position: -286px 20px;
    ${up("md")} {
      background-position: 386px 20px;
    }
  }
  header {
    background-color: transparent;
    .container {
      width: 100%;
    }
  }
`
const HeroText = styled.h1`
  font-family: "CeraGR-Regular";
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #ffffff;
  position: relative;
  margin: 50px 0 100px;
  ${down("md")} {
    margin: 40px 0 70px;
  }
`
const StickySection = styled.section`
  background: #f2f2f2;
  padding-top: 35px;
  padding-bottom: 35px;

  ${down("lg")} {
    display: none;
  }
`

// const TabSelect = ({ options, tab, setTab }) => {
//   return (
//     <div className="mb-3">
//       <Select
//         options={options}
//         width="100%"
//         value={tab}
//         onChange={v => setTab(v)}
//       />
//     </div>
//   )
// }

export default function Page({ location, data }) {
  const [selectedTab, setSelectedTab] = useState(
    location.hash ? location.hash.split("#")[1] : "gsevee"
  )
  const { t } = useTranslation()

  const tabs = [
    { label: t("about:tabs.gsevee"), id: "gsevee" },
    {
      label: t("about:tabs.role"),
      id: "role",
    },
    { label: t("about:tabs.challenges"), id: "challenges" },
    {
      label: t("about:tabs.bodies"),
      id: "bodies",
    },
    {
      label: t("about:tabs.timeline"),
      id: "timeline",
    },
    {
      label: t("about:tabs.services"),
      id: "services",
    },
  ]

  return (
    <Layout location={location}>
      <Seo title={t("about:seoTitle")} />

      <AboutHero>
        <Navbar type="second" />
        <div className="container">
          <HeroText>{t("about:heroText")}</HeroText>
        </div>
      </AboutHero>
      <StickySection
        css={`
          scroll-padding-bottom: 128px;
        `}
      >
        <div className="container" id="parent0">
          <div className="desktop-activities">
            <StickyWrapper>
              <div className="d-flex mx-0 mb-4 pt-4 justify-content-between">
                {tabs.map(tab => (
                  <CategoryButton
                    key={tab.id}
                    href={`#${tab.id}`}
                    isSelected={selectedTab === tab.id}
                    onClick={() => setSelectedTab(tab.id)}
                  >
                    {tab.label}
                  </CategoryButton>
                ))}
              </div>
            </StickyWrapper>
          </div>

          {/* <div className="mobile-activities">
            <TabSelect options={tab_filters} tab={tab} setTab={setTab} />
          </div> */}
        </div>
      </StickySection>
      <section id="bounder" name="bounder">
        <AboutTab />
        <PowerTab />
        <InstitutionalTab />
        <CategoryTab categories={data.allPrismicCategory.nodes} />
        <ChallengeTab />
        <AffiliatesTab />
        <TimelineTab />
        <AdminTab />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query AboutQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allPrismicCategory(
      sort: { fields: data___order, order: ASC }
      filter: { lang: { eq: $language } }
    ) {
      nodes {
        data {
          title
          color
          icon {
            url
            gatsbyImageData
          }
        }
        uid
      }
    }
  }
`
