import React from "react"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ImageEl from "../../images/svgs/about-incorporates-el.inline.svg"
import ImageEn from "../../images/svgs/about-incorporates-en.inline.svg"

const Tab = styled.div`
  padding-top: 96px;
  padding-bottom: 96px;
  background-color: white;
  text-align: center;
  h2 {
    font-family: "CeraGR-Bold";
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.02em;
    margin-bottom: 50px;
  }
  ${down("md")} {
    padding-top: 40px;
    padding-bottom: 40px;
    .power-img {
      width: 100%;
    }
  }
`

export default function PowerTab() {
  const { t, i18n } = useTranslation()

  return (
    <Tab>
      <div className="container">
        <h2>{t("about:incorporates.title")}</h2>
        {i18n.resolvedLanguage === "el-gr" && (
          <ImageEl style={{ width: "min(784px, 100%)", height: "auto" }} />
        )}
        {i18n.resolvedLanguage === "en-us" && (
          <ImageEn style={{ width: "min(784px, 100%)", height: "auto" }} />
        )}
      </div>
    </Tab>
  )
}
