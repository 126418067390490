import React from "react"
import { down } from "styled-breakpoints"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import { blocks } from "../../utils/staticData"
import { useTranslation } from "gatsby-plugin-react-i18next"

const Tab = styled.div`
  padding: 120px 0;
  background: #f2f2f2;
  .title {
    max-width: 467px;
    font-family: "CeraGR-Bold";
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.02em;
    color: #002f6c;
  }
  .scroll-wrapper {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
  ${down("md")} {
    padding: 60px 0;
  }
`
const BlockBox = styled.div`
  height: 106px;
`

export default function ChallengeTab() {
  const { t } = useTranslation()

  return (
    <Tab id="challenges">
      <div className="container">
        <h2 className="title">{t("about:challenges.title")}</h2>
      </div>
      <Swiper grabCursor={true} className="mySwiper">
        <SwiperSlide>
          <div className="d-flex flex-nowrap my-5">
            {blocks.map((item, idx) => (
              <img className="me-4" src={item} key={idx} alt="block" />
            ))}
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <p>{t("about:challenges.textOne")}</p>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <BlockBox className="my-5"></BlockBox>
          <div className="container">
            <div className="row">
              <div className="col-md-6"></div>
              <div className="col-md-6">
                <p>{t("about:challenges.textTwo")}</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </Tab>
  )
}
